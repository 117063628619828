/* Estilos para el componente ConsumirApi */
.productosDivAPI2 {
    font-family: Arial, sans-serif;
    margin: 0 auto;
   
  }
  
  h1 {
    text-align: center;
  background-color: #f39c12; /* Naranjaa */
  color: white;
  padding: 10px;

  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f39c12; /* Naranja */
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f7dc6f; /* Naranja claro */
  }



  .productosDivAPI{
    min-width: 825px;
  }

  .buscadorProd{
    width: 300px;
    height: 46px;
    font-size: 25px;
    border: 4px solid #f39c12; /* Naranja */
    margin-top: 18px;
    margin-left: 10px;
  }
  .tablaPedidosMobile{
    display: none;
  }

/* Estilos para dispositivos móviles */
/* Estilos para dispositivos móviles */
@media (max-width: 813px) {
    .tablaPedidosMobile{
        display:table-cell;
      }
    .productosDivAPI2 {
      max-width: 100%; /* Utiliza el 100% del ancho de la pantalla */
      overflow-x:hidden;
    }
  
    .buscadorProd {
      width: 100%; /* Ocupa todo el ancho disponible */
      margin: 10px 0; /* Agrega espacio en la parte superior e inferior */
    }
  
    table {
      font-size: 16px; /* Reduce el tamaño de fuente para la tabla */
   
      
    }
    .tablaPedidosGrande{
        display: none;

    }
    .table-product-mobile-div{
        width: 17em;
        background-color: transparent;
    }
  

  }
  