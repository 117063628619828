.Entrenamientos {

   
        display: flex;
        flex-direction: column;
        padding-bottom: 2em;
        margin-top:0.5em;
        border-top: solid 0.6em var(--two-color);

}

.h2Capacitaciones{
    font-size: 2em;
    margin-left: 0.6em;
    margin-top:0.6em;
    width: fit-content;
    text-align: start;
    text-decoration:underline;
    margin-bottom: 1em;

}
.h4Capacitaciones{
    font-size: 1.3em;
    text-align: center;
    padding: 0.3em 0.5em;
  
}
.areaCapacitaciones{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 0em 2em;

}

.areaCapacitaciones p {
    display: flex;
    max-width: 40em;
    padding: 1em;
   
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top:0px;
    margin-bottom:4px;
}

@media (width <=813px) {

    .areaCapacitaciones{
       
        padding: 0em 0.3em;
    
    }

}