/* Estilos para el componente ConsumirApi */
.ConsumirApi {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 800px;
  }
  
  h1 {
    text-align: center;
  background-color: #f39c12; /* Naranja */
  color: white;
  padding: 10px;

  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f39c12; /* Naranja */
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f7dc6f; /* Naranja claro */
  }

  .ocultar{
    display: none;
  }


  .productosDivAPI{
    min-width: 825px;
  }

  .buscadorProd{
    width: 300px;
    height: 46px;
    font-size: 25px;
    border: 4px solid #f39c12; /* Naranja */
    margin-top: 18px;
    margin-left: 10px;
  }