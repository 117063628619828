.Box {
    width: 100%;
    max-width: 450px;
    height:100%;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    background-color: #e9fffe;
    position: fixed;
    z-index: 2;
    padding: 0px;
   
    left:0%;
    top: 3.75em;

    transition: left 0.4s;

    
    
}
.ocultar{
    left:-100%;
}
.Box ul {
    background-color: transparent;
    padding-left: 0px;
    padding-bottom: 3.75em;
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  padding-top: 0.3em;
 
    
}
.Box ul li {
   
    display: flex;
    width: 100%;
    flex-direction: column;
   justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0.7em 0em;
    background-color: transparent;
    border-bottom: var(--nav-color) solid 0.2em;

    transition: background-color 180ms;

    
}
.Box ul li:hover {
    background-color: #278fb8;
}
.Box ul li a{
    text-decoration: none;
    color: black;
    font-size: 2.5em;
    width: 100%;

    
    text-align: center;
    background-color: transparent;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
