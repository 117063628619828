.productosDivAPI {
    text-align: center;
    padding: 20px;
  }
  
  .tituloh1-item-limpieza {
   
    margin-bottom: 20px;
  }
  
  .buscadorProd {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .item-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
  }
  
  .item {
    display: flex;
    flex-direction: column;
    justify-content:start;

    align-items: start;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width:23em;
    height: 37em;
    margin:1em;
    background-color: #fcf3e4;
  }

  .div-imagen-item-limpieza {
    background-color: transparent;
    height: 17em;
    width: 100%;
    padding: 0.5em;
    
    
    
  }
  
  .img-item-limpieza {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  
  .item-details {
    margin-top: 10px;
    text-align: left;
    max-width: 50em;
  }
  
  .item-details strong {
    font-weight: bold;
  }
  
  .lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    text-align: center;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lightbox-image {
    max-width: 95%;
    max-height: 95vh;
    width: auto;
    height: auto;
    cursor: zoom-out;
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
  }
  
  .close-button:hover {
    color: #ccc;
  }
  
  .buscadorProd2{
    width: 300px;
    height: 46px;
    font-size: 25px;
    border: 4px solid #f39c12; /* Naranja */
    margin-top: 18px;
    margin-left: 10px;
  }

  
  .productosDivAPI2{
    
  }

