.Noticias {



    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    margin-top: 0.5em;
    border-top: solid 0.6em var(--two-color);
 


}

.h2Noticias {
    font-size: 2em;
    margin-left: 0.6em;
    margin-top: 0.6em;
    width: fit-content;
    text-align: start;
    text-decoration: underline;
    margin-bottom: 1em;
    cursor: pointer;

}
.areaNoticias{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

}