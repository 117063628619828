.Footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    background-color: var(--nav-color);
    width: 100%;

    padding-top:1.8em;
    padding-bottom: 1em;
    border-top: var(--two-color) solid 0.6em;

}
.footerContact{
    padding-bottom: 0.5em;
}
.h3footer{
    font-size: 1.35em;
    padding-bottom: 0.2em;

}
.areaContactos div p{
    font-size: 1.1em;
    padding:0.3em 0em;
    padding-left: 0.3em;
    background-color: transparent;
}
.totalContacto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.itemContacto{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.totalRedes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.areaLogos{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.email-a{
    all:unset;
    cursor: pointer;
}
.tel-a{
    all:unset;
    cursor: pointer;
}
.redesIcon{
    width:4.1em;
    margin:0.6em;
    cursor: pointer;
    transition: transform 0.3s;
}
.redesIcon:hover{
    transform: rotate(-18deg);
}

img, svg {
    vertical-align: middle;
    margin-bottom: 13px;
}

@media (width <=1370px) {

    .Footer {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        background-color: var(--nav-color);
        width: 100%;
        padding:1em 2em;
    
        border-top: var(--two-color) solid 0.6em;
    
    }
    .footerContact{
        display: flex;
        flex-direction: column;
        height: 100%;
        
        justify-content: center;
        align-items: center;
        
        padding:0em;
        margin: 0em;

    }
    .h3footer{
        font-size: 1.35em;
        margin: 0em;
        padding: 0em;
       
    
    }
    .areaContactos{
        display: flex;
        flex-direction: column;
        margin: 0em;
        padding:0em;
    }
    .areaContactos div p{
        font-size: 1.1em;
        padding-left: 0.3em;
        margin: 0em;
    }
    .totalContacto{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin:0.3em;
    
    }
    .itemContacto{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin:-0.25em;
    
    }
    .footerRedes{
        padding:2em 0em;
    }
    
    .totalRedes{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    
    }
    .areaLogos{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .email-a{
        all:unset;
        cursor: pointer;
    }
    .tel-a{
        all:unset;
        cursor: pointer;
    }
    .redesIcon{
        width:4.1em;
        margin:0.6em;
        cursor: pointer;
        transition: transform 0.3s;
    }
    .redesIcon:hover{
        transform: rotate(-18deg);
    }
    
    img, svg {
        vertical-align: middle;
        margin-bottom: 13px;
    }
    .svgContact{
        margin-top: 0.89em;
        height: 100%;
    }

}

@media (width <=813px) {
    .Footer {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        background-color: var(--nav-color);
        width: 100%;
        padding:1em 1em;
        padding-bottom:0.4em;
    
        border-top: var(--two-color) solid 0.6em;
    
    }

}


