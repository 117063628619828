.Contacto {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;


}
.itemMapa{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:0px;
    padding: 0px;
    background-color: transparent;
    font-size: 1.1em;
    padding-top: 0.4em;

}
.mapaConjunto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    max-width: 700px;
    margin: 0px;
    padding: 0px;
    padding-top: 1.7em;
}

.Contacto22 {
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    margin-top:0.5em;
    border-top: solid 0.6em var(--two-color);
  

}
.divJunta{
    max-width: 600px;
    border-bottom: solid black 5px;
    padding-bottom: 1.3em;
    
}

#formBtn{
    height: 64px;
    font-size: 29px;
    border: none;
    background-color: #4cbae1;
    margin-top: -10px;
    margin-bottom: 5px;
}

.h2Contacto{
    font-size: 2em;
    margin-left: 0.6em;
    margin-top:0.6em;
    width: fit-content;
    text-align: start;
    text-decoration:underline;
    margin-bottom: 1em;

}
.mapaGoogle{
    margin:0em 0em;
    max-width: 700px;
   
}
.formulario{
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 600px;
    

}
.formulario label{
    font-size: 1.54em;
}

.formulario input{
    width:90%;
    height:1.7em;
    font-size: 1.2em;
    
}

.formulario textarea{
    width:90%;
    height:7.7em;
    font-size: 1.3em;
    
}

.h444Nosotros{
    font-size: 1.3em;
    text-align: center;
    padding: 0.3em 0.5em;
    background-color: rgba(81, 157, 185, 0.46);
}



@media (width <=1370px) {

    .Contacto {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        padding:0em 4em;
    
    
    }
    .itemMapa{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin:0px;
        padding: 0px;
        background-color: transparent;
        font-size: 1.1em;
        padding-top: 0.4em;
    
    }
    .mapaConjunto{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 100%;
        max-width: 34em;
        margin: 0px;
        padding: 0px;
        padding-top: 1.7em;
    }
    
    .Contacto22 {
        display: flex;
        flex-direction: column;
        padding-bottom: 2em;
        margin-top:0.5em;
        border-top: solid 0.6em var(--two-color);
      
    
    }
    .divJunta{
        max-width: 600px;
        border-bottom: solid black 5px;
        padding-bottom: 1.3em;
        
    }
    
    #formBtn{
        height: 2.4em;
        font-size: 1.4em;
        border: none;
        background-color: #4cbae1;
        margin-top: -10px;
        margin-bottom: 5px;
    }
    #formBtn:hover{
        background-color: #149fd2;
        

    }
    
    .h2Contacto{
        font-size: 2em;
        margin-left: 0.6em;
        margin-top:0.6em;
        width: fit-content;
        text-align: start;
        text-decoration:underline;
        margin-bottom: 1em;
    
    }
    .mapaGoogle{
        margin:0em 0em;
        max-width: 700px;
       
    }
    .formulario{
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 600px;
        
    
    }
    .formulario label{
        font-size: 1.2em;
    }
    
    .formulario input{
        width:90%;
        height:1.7em;
        font-size: 1.15em;
        
    }
    
    .formulario textarea{
        width:90%;
        height:4em;
        max-height: 8em;
        font-size: 1.15em;
        
    }
    
    .h444Nosotros{
        font-size: 1.3em;
        text-align: center;
        padding: 0.3em 0.5em;
        background-color: rgba(81, 157, 185, 0.46);
    }


}

@media (width <=813px) {

    .Contacto {
    
        padding:0em;
    
    
    }

    .formulario input{
        width:90%;
        height:2.29em;
        font-size: 1.15em;
        
    }
    
    .formulario textarea{
        width:90%;
        height:5em;
        max-height: 8em;
        font-size: 1.15em;
        
    }

}