/* Estilos para el componente StockQuimicos */

/* Estilo para el contenedor de la tabla */
.table-container {
 
  min-width: 25em;
  width: 100%;
  overflow-x: auto; /* Habilitar el desplazamiento horizontal */
  
  margin-bottom: 2em;
}

.stock-quim-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  background-color: transparent;
}
.parent-stock-quim-container{
  width: 100%;
  background-color: transparent;
}
/* Estilos para el título general */
h1 {
    text-align: center;
    background-color: #f39c12; /* Naranja */
    color: white;
    padding: 10px;
    margin-top: 20px; /* Agregamos margen arriba */
    width: 100%;
  }
  
  /* Estilos para la tabla */
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px; /* Reducimos el margen superior */
  }
  
  th, td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd; /* Borde derecho en las celdas */
  }
  
  th {
    background-color: #f39c12; /* Naranja */
    color: white;
  }
  
  /* Estilos para la primera fila */
  tr:first-child {
    border-top: 2px solid #ddd; /* Borde superior en la primera fila */
  }
  
  /* Estilos para la última fila */
  tr:last-child {
    border-bottom: 2px solid #ddd; /* Borde inferior en la última fila */
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f7dc6f; /* Naranja claro */
  }

  .tr-prod-stock-quim td{
    background-color: #e2a440; /* Naranja */
    text-align: center;
    
  }
  .tr-prod-stock-quim2 td{
    background-color: #d8b885; /* Naranja */
    text-align: center;
    
  }


  @media (width <=813px) {
    .oculta-stock{
      display: none;
    }
  }