
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente blanco */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Asegúrate de que esté por encima de otros elementos */
}


.loading-message {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Color del texto */
}

.success-message,
.error-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
    text-align: center;
    font-size: 1em !important;
    font-weight: bold;
    color: #fff !important; /* Texto blanco */
}

.success-message {
    background-color: rgba(0, 128, 0, 0.8); /* Fondo verde para éxito */
}

.error-message {
    background-color: rgba(255, 0, 0, 0.8); /* Fondo rojo para error */
}


.form-pago-1 {

    padding: 13px;
   


}
.form-pago-h2{
    text-align: center;
    margin-bottom: 10px;
}
.form-pago-1 .container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff; /* Fondo blanco */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Sombra suave */
}

/* Estilos para encabezado */
.form-pago-1 h2 {
  color: #0074e4; /* Azul */
}

/* Estilos para select y input (excluyendo el cliente) */
.form-pago-1 select,
input[type="number"],
button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #0074e4; /* Borde azul */
  border-radius: 5px;
  background-color: #e4f2ff; /* Fondo azul claro */
  font-size: 16px;
  color: #0074e4; /* Texto azul */
}

/* Estilos para el campo "Elige un cliente" */
.form-pago-1 #cliente {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 29px;
  border: 1px solid #0074e4; /* Borde azul */
  border-radius: 5px;
  background-color: #e4f2ff; /* Fondo azul claro */
  font-size: 16px;
  color: #0074e4; /* Texto azul */
  padding-bottom: 15px; /* Agregado el padding-bottom */
}

.form-pago-1 .agregar-pago1{
    background-color: #2d76ba;
}

.form-pago-1 .agregar-pago2{
  background-color: #1f7ea6;
}

/* Estilos para botones */
.form-pago-1 button {
  background-color:  #11426f;/* Azul */
  color: #fff; /* Texto blanco */
  font-weight: bold;
  cursor: pointer;
}

/* Estilos para etiquetas y campos */
.form-pago-1 label {
  font-weight: bold;
  color: #333; /* Texto gris oscuro */
  padding: 5px;
  border-radius: 5px;
  background-color: transparent; /* Fondo transparente */
  color: #333; /* Texto gris oscuro */
}

/* Estilos para mensajes de error (si es necesario) */
.form-pago-1 .error-message {
  color: #ff5722; /* Rojo */
  font-size: 14px;
  margin-top: 5px;
}

.form-pago-1 .pago{
    margin-bottom: 25px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 600px) {
  .form-pago-1 .container {
    max-width: 100%;
    padding: 10px;
  }
}
