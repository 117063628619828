.Portada {
    background-color: transparent;
    width: 100%;
    margin-top: 3.75em;
    z-index: 1;

}
.foto1{
   
    width:100%;
    
    object-fit: cover;
   
  
}
.sliderPortada{
    height: 37vw;
    z-index: -25;
    background-color: white;
}
.divportada1{
    background-color: transparent;
    width: 100%;
}
