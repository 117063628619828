:root {
  --main-color: rgb(222, 238, 248);
  --two-color: #4b727d;
  --nav-color:#1d95b7;
  --contrast-color:#de7a1d;

}
*{
  margin:0px;
  padding: 0px;
}
body {
  margin: 0;
  font-family: 'Nunito', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 390px;
  
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

