.ExcelPreciosCargando{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Fondo semitransparente blanco */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Asegúrate de que esté por encima de otros elementos */
}
